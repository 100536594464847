<template>
  <div>
    <ins
      v-if="type == 'ad'"
      class="adsbygoogle"
      style="display: block"
      data-ad-client="ca-pub-4498274213055914"
      :data-ad-slot="getAdsID()"
      data-ad-format="auto"
      data-full-width-responsive="true"
    />
    <ins
      v-if="type == 'ad2'"
      class="adsbygoogle"
      style="display: block"
      data-ad-client="ca-pub-4498274213055914"
      data-ad-format="horizontal"
      data-full-width-responsive="true"
      :data-ad-slot="getAdsID()"
    />
    <ins v-if="type == 'ad-v'" class="adsbygoogle" style="display: block" data-ad-client="ca-pub-4498274213055914" :data-ad-slot="getAdsID()" data-ad-format="auto" />
    <ins
      v-if="type == 'ad2-v'"
      class="adsbygoogle"
      style="display: block; width: 728px; height: 90px"
      data-ad-format="auto"
      data-ad-client="ca-pub-4498274213055914"
      data-full-width-responsive="true"
      :data-ad-slot="getAdsID()"
    />
  </div>
</template>
<script>
export default {
  props: {
    type: String,
    id: String,
  },
  data() {
    return {};
  },
  methods: {
    getAdsID(n = 0) {
      return this.id;
    },
  },
};
</script>