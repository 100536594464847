<template>
  <header class="main-header">
    <nav class="navbar">
      <a href="#" class="logo"></a>

      <!-- Пункты меню для широких экранов -->
      <ul class="nav-menu desktop-menu">
        <li v-for="m of menu" :key="m.name">
          <a :href="m.url">{{ m.title }}</a>
        </li>
      </ul>

      <!-- Кнопка гамбургера для мобильных устройств -->
      <button class="hamburger" @click="toggleMenu">☰</button>

      <!-- Пункты меню для мобильных устройств -->
      <ul class="nav-menu mobile-menu" v-show="isMenuOpen">
        <li v-for="m of menu" :key="m.name">
          <a :href="m.url">{{ m.title }}</a>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      isMenuOpen: false, // Отслеживаем состояние меню
      menu1: [
        { name: "site", title: "Joldo.kg", url: "https://joldo.kg" },
        { name: "choise", title: "Выбор режима", url: "https://joldo.kg/ru/pdd.html" },
        { name: "rating", title: "Рейтинг", url: "https://joldo.kg/ru/rating.html" },
        { name: "rules", title: "Правила", url: "https://joldo.kg/ru/a/reyting---kak-popast-v-top.html" },
      ],
    };
  },
  computed: {
    lang() {
      return this.$route.meta.lang || "ru";
    },
    menu() {
      const res = [
        { name: "site", title: "Joldo.kg", url: "https://joldo.kg" },
        {
          name: "choise",
          title: "Выбор режима",
          url: this.lang == "ru" ? "https://joldo.kg/ru/pdd.html" : "https://joldo.kg/ru/onlayn-test-2023-kyrgyzcha.html",
        },
        { name: "rating", title: "Рейтинг", url: "https://joldo.kg/ru/rating.html" },
        {
          name: "rules",
          title: "Правила",
          url: "https://joldo.kg/ru/a/reyting---kak-popast-v-top.html",
        },
      ];
      return res;
    },
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>
<style scoped>
/* Основные стили для header */
.main-header {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #838383;
  color: #fff;
  z-index: 1000;
}

/* Стили для навигации */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 20px;
}

.logo {
  font-size: 1.5em;
  color: #fff;
  text-decoration: none;
}

/* Основные стили меню */
.nav-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Стили пунктов меню для широких экранов */
.desktop-menu {
  display: flex;
}

.desktop-menu li {
  margin-left: 20px;
}

.desktop-menu a,
.mobile-menu a {
  color: #fff;
  text-decoration: none;
  font-size: 1em;
}

/* Гамбургер скрыт на больших экранах */
.hamburger {
  display: none;
  font-size: 18px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

/* Стили для мобильных устройств */
@media (max-width: 500px) {
  /* Скрываем пункты меню для широких экранов */
  .desktop-menu {
    display: none;
  }

  /* Показываем меню для мобильных, когда оно активно */
  .mobile-menu {
    position: absolute;
    top: 43px;
    left: 0;
    width: 100%;
    background-color: #838383;
    flex-direction: column;
    text-align: center;
    padding: 10px 0;
  }

  .mobile-menu[v-show="true"] {
    display: flex;
  }

  /* Показываем кнопку гамбургера */
  .hamburger {
    display: block;
  }
}
</style>