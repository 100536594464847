/** @format */

import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/config/routes';

Vue.use(VueRouter);

const router = new VueRouter({
  saveScrollPosition: true,
  //history: true,
  mode: 'history',
  //mode: 'hash',
  //base: process.env.BASE_URL,
  //base: '/pdd-v4/',
  routes: [
    {
      path: '/',
      redirect: (to) => {
        return '/main';
      },
    },
    {
      path: '/main',
      meta: {
        auth: 1,
      },
      component: () => import('@/layouts/main.vue'),
      children: [
        {
          path: '/main',
          name: 'main',
          component: () => import('@/views/Home.vue'),
        },
      ],
    },
    {
      path: '/training',
      meta: { auth: 1 },
      component: () => import('@/layouts/pdd.vue'),
      children: routes.routes,
    },
    {
      path: '/',
      component: () => import('@/layouts/blank.vue'),
      children: [
        {
          path: '*',
          name: 'notfound',
          meta: { auth: '*' },
          component: () => import('@/views/notFound.vue'),
        },
      ],
    },
  ],
});

export default router;
