/** @format */

const routes = [
  {
    path: '/training/ru',
    name: 'training',
    meta: { isHard: false, lang: 'ru', disabled: true },
    component: () => import('../views/Testing.vue'),
  },
  {
    path: '/training/kg',
    name: 'training',
    meta: { isHard: false, lang: 'kg', disabled: true },
    component: () => import('../views/Testing.vue'),
  },
  {
    path: '/topic/ru',
    name: 'topic',
    meta: { isHard: false, lang: 'ru', type: 'topic' },
    component: () => import('../views/Testing.vue'),
  },
  {
    path: '/topic/kg',
    name: 'topic',
    meta: { isHard: false, lang: 'kg', type: 'topic' },
    component: () => import('../views/Testing.vue'),
  },
  {
    path: '/exam/ru',
    name: 'exam', 
    meta: { isHard: true, lang: 'ru', type: 'exam' },
    component: () => import('../views/Testing.vue'),
  },
  {
    path: '/exam/kg',
    name: 'exam',
    meta: { isHard: true, lang: 'kg', type: 'exam' },
    component: () => import('../views/Testing.vue'),
  },
  {
    path: '/rush/ru',
    name: 'rush',
    meta: { isHard: true, lang: 'ru', type: 'rush' },
    component: () => import('../views/Testing.vue'),
  },

  {
    path: '/rush/kg',
    name: 'rush',
    meta: { isHard: true, lang: 'kg', type: 'rush' },
    component: () => import('../views/Testing.vue'),
  },
  {
    path: '/marathon/ru',
    name: 'marathon',
    meta: { isHard: false, lang: 'ru', type: 'marathon' },
    component: () => import('../views/Testing.vue'),
  },
  {
    path: '/marathon/kg',
    name: 'marathon',
    meta: { isHard: false, lang: 'kg', type: 'marathon' },
    component: () => import('../views/Testing.vue'),
  },
  {
    path: '/bilet/ru',
    name: 'bilet',
    meta: { isHard: false, lang: 'ru', type: 'bilet' },
    component: () => import('../views/Testing.vue'),
  },
  {
    path: '/bilet/kg',
    name: 'bilet',
    meta: { isHard: false, lang: 'kg', type: 'bilet' },
    component: () => import('../views/Testing.vue'),
  },
  {
    path: '/favorites/ru',
    name: 'bilet',
    meta: { isHard: false, lang: 'ru', type: 'favorites' },
    component: () => import('../views/Testing.vue'),
  },
  {
    path: '/favorites/kg',
    name: 'bilet',
    meta: { isHard: false, lang: 'kg', type: 'favorites' },
    component: () => import('../views/Testing.vue'),
  },
  {
    path: '/myErrors/ru',
    name: 'bilet',
    meta: { isHard: false, lang: 'ru', type: 'myErrors' },
    component: () => import('../views/Testing.vue'),
  },
  {
    path: '/myErrors/kg',
    name: 'bilet',
    meta: { isHard: false, lang: 'kg', type: 'myErrors' },
    component: () => import('../views/Testing.vue'),
  },
];

export default {
  routes: routes,
};
