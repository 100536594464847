<template>
  <div>
    <div class="modal-backdrop fade show"></div>
    <div class="modal fade show" tabindex="-1" role="dialog" style="display: block" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Обратите внимание</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal('close')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">Вопросы из категории CD предназначены только для обучения. Эти вопросы не будут на экзаменах в ГРС.</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal('confirm')">Понятно</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeModal(action) {
      this.$emit("close", action);
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Прозрачный чёрный цвет */
  z-index: 1040; /* z-index ниже модального окна */
}
.modal {
  z-index: 1050; /* z-index выше подложки */
}
</style>
