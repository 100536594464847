/** @format */

export default {
  kg: {
    '?': '?',
    Ticket: 'Билет',
    test_guide_text: `На экзамен даётся 25 минут.<br>Внимательно читайте каждый вопрос и все варианты ответов.<br>Не торопитесь!`,
    test_guide_text2: `Попробуйте пройти экзамен на скорость и без подсказок.<br>Поднимитесь в топ таблицы рейтинга!`,

    marathon_name_text: `МАРАФОН`,
    marathon_guide_text: `Саны: 800+<br>Убакыт: чексиз<br><br>Катасыз болсо: 2500 балл<br>1-40 каталар болсо: 1800 балл<br>41-80 же андан көп болсо: 1600 балл<br>81-200 же андан көп болсо: 200 балл`,
    topic_name_text: `ТЕМАЛАР БОЮНЧА`,
    favorites_name_text: `ТАНДАМАЛАР`,
    myErrors_name_text: `КАТАЛАРЫМ`,
    exam_name_text: `ЭКЗАМЕН`,
    exam_guide_text: `Убакыт: 25 мин | Саны: 20<br>`,
    rush_name_text: `ЭКЗАМЕН`,
    rush_guide_text: `Убакыт: 25 мин | Саны: 20<br>`,
    ОТВЕТ: 'ЖООП',
    'Дополнительный вопрос': 'Кошумча суроо',
    'Экзаменационный вопрос': 'Суроо экзаменде болот',
    Registration: 'Регистрация',
    Login: 'Войти',
    close: 'Закрыть',
    cancel: 'Отменить',
    clear: 'Очистить',
    ok: 'Ок',
    All: 'Все',
    'login error': 'Ошибка входа',
    'Количество вопросов': 'Суроолордун саны',
    'Вопросы JOLDO': `Суроолор JOLDO`,
    'Включить дополнительные вопросы': 'Кошумча суроолорду кошуңуз',
  },
};
