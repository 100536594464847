/** @format */

export default {
  ru: {
    '?': '?',
    Ticket: 'Билет',
    myErrors_name_text: `Мои ошибки`,
    test_guide_text: `В течение 20 минут вам нужно решить 20 случайно выбранных вопросов.<br>В режиме «Тренировка» доступны подсказки.`,
    test_guide_text2: `Попробуйте пройти экзамен на скорость и без подсказок.<br>Поднимитесь в топ таблицы рейтинга!`,
    marathon_name_text: `МАРАФОН`,
    marathon_guide_text: `Количество: 800+<br>Время: неограниченно<br><br>Без ошибок: 2500 баллов<br>1-40 ошибок: 1800 баллов<br>41-80 ошибок: 1600 баллов<br>81-200 ошибок: 200 баллов`,
    topic_name_text: `ПО ТЕМАМ`,
    favorites_name_text: `ИЗБРАННОЕ`,
    myErrors_name_text: `МОИ ОШИБКИ`,
    exam_name_text: `ЭКЗАМЕН`,
    exam_guide_text: `Время: 25 мин | Вопросов: 20<br>`,
    rush_name_text: `Режим экзамена`,
    rush_guide_text: `Время: 25 мин | Вопросов: 20<br>`,
  },
};
