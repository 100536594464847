<template>
  <div>
    <div>Получено за тест:</div>
    <table style="text-align: center; margin: auto">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" class="px-3">Очки</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(el, i) in nowList" :key="i">
          <td class="p-1">
            <img v-if="el.image" :src="getImage(el)" class="img-fluid" :alt="el.title" style="height: 100px" />
            <div v-else>{{ el.title }}</div>
          </td>
          <td class="p-1">{{ el.count }}</td>
          <td class="p-1">{{ el.points }}</td>
        </tr>
      </tbody>
    </table>
    <!--    <div>Ваш общий результат:</div>

    <table style="text-align: center; margin: auto">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" class="px-3">Кол-во</th>
          <th scope="col" class="px-3">Очки</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(el, i) in rewardsList" :key="i">
          <td class="p-1"><img :src="getImage(el)" class="img-fluid" :alt="el.title" style="height: 100px" /></td>
          <td class="p-1">{{ el.count }}</td>
          <td class="p-1">{{ el.points }}</td>
        </tr>
      </tbody>
    </table>
  --></div>
</template>
<script>
import { reward } from "@/components/mixings";
export default {
  mixins: [reward],
  props: {
    data: { type: Array, default: () => [] },
  },
  data() {
    return {
      rewardsList: [],
      nowList: [],
    };
  },
  created() {
    this.calcRewards();
  },
  destroyed() {},
  computed: {},
  methods: {
    getImage(el) {
      let res = "https://joldo.kg/uploads/rewards/";
      return el.image ? res + el.image : null;
    },
    async calcRewards(type = "total") {
      let list = [];
      if (type == "total") {
        let rewards = await this.getRewards({ status: 2, start: this.date_start.date, end: this.date_end.date });
        rewards.forEach(r => {
          let rew = this.readReward(r.name);
          list.push({ name: r.name, title: rew.title, count: r.count, points: r.points, new: false });
        });
      }
      if (type == "total") {
        let l = [];
        console.log("current rewards", [...this.data]);
        this.data
          .filter(f => !f.ignore)
          .forEach(r => {
            if (r.status == 2) {
              let rew = this.readReward(r.name);
              l.push({ name: r.name, title: rew.title, count: r.count, points: r.points, new: true });
            }
          });
        this.nowList = l;
      }
      this.rewardsList = list;
    },
  },
};
</script>