/** @format */

import Vue from 'vue';
import axios from 'axios';
import config from '../config/config';

axios.defaults.headers.common['Sec-Fetch-Site'] = 'same-origin';
axios.defaults.baseURL = config.siteUrl;
Vue.prototype.$axios = axios;

export default axios;
