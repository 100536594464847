<template>
  <footer id="pageFooter" class="text-center my-3">
    <div class="container">
      <p>
        <a href="https://joldo.kg">Joldo.kg</a> © 2024. <br class="d-md-none" />Все права сохраняются |
        <a href="/ru/politika-konfidencial-nosti.html">Политика конфиденциальности</a>
      </p>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    data: { type: Array, default: () => [] },
  },
  data() {
    return {};
  },
  created() {},
  destroyed() {},
  computed: {},
  methods: {},
};
</script>