/** @format */

export default {
  appName: 'pdd',
  version: 48,
  baseUrl: 'https://joldo.kg:8453',
  siteUrl: 'https://joldo.kg',

  //baseUrl: 'https://81.163.31.148/api',
  //websocetUrl: 'wss://api.gk-mechti.ru/data',
  //videoUrl: 'https://api.gk-mechti.ru/api/file/download/',
  //imageUrl: 'https://api.gk-mechti.ru/api/image/download/',
  //fileUrl: 'https://api.gk-mechti.ru/api/file/download/',
  //  recordUrl: 'https://tel.gk-mechti.ru/',
};
