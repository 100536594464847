<template>
  <div class="container mt-4">
    <div v-if="type == 'exam' || type == 'test'" class="row justify-content-md-center my-3">
      <div class="col-md-auto">
        <h3>Режим “Экзамен”</h3>
        <span>
          Имитирует реальные условия теоретического экзамена по ПДД КР. Вы получите случайный набор вопросов, на которые нужно ответить за ограниченное время. Этот режим
          помогает не только проверить свои знания, но и привыкнуть к формату и давлению реального экзамена. В конце теста вы получите оценку и подробный разбор ваших
          ответов. В этом режиме вам доступны 20 вопросов из разных тематик. Время прохождения ограничено 25 минут (как на экзамене в  ГРС). 
        </span>
      </div>
    </div>
    <div v-if="type == 'topic' || type == 'test'" class="row justify-content-md-center my-3">
      <div class="col-md-auto">
        <h3>Режим “По темам”</h3>
        <span>
          Режим “По темам” позволяет сосредоточиться на определенных разделах ПДД КР, таких как дорожные знаки, правила обгона или парковки. Выберите интересующую вас
          тему и проходите тестирование только по вопросам, связанным с этой темой. Это помогает углубленно изучить и закрепить знания по конкретным аспектам правил
          дорожного движения.
        </span>
      </div>
    </div>
    <div v-if="type == 'bilet' || type == 'test'" class="row justify-content-md-center my-3">
      <div class="col-md-auto">
        <h3>Режим “По билетам”</h3>
        <span>
          В режиме “По билетам” вы можете выбрать конкретный билет из официального перечня и пройти тестирование по вопросам, содержащимся в этом билете. Это позволяет
          систематически изучать материал и проверять свои знания по каждому билету отдельно. Вы можете вернуться к любому билету в любое время для повторного прохождени
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: { type: Array, default: () => [] },
  },
  data() {
    return {
      type: this.$route.meta.type || "test",
    };
  },
  created() {},
  destroyed() {},
  computed: {},
  methods: {},
};
</script>