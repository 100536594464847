<template>
  <div>
    <div v-if="type == 'ad'" class="d-grid text-center">
      <ins
        class="adsbygoogle"
        style="display: block"
        data-ad-client="ca-pub-4498274213055914"
        :data-ad-slot2="'1638399585'"
        :data-ad-slot="getAdsID()"
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </div>
    <div v-if="type == 'ad2'" style="d-grid text-center">
      <ins class="adsbygoogle" style="display: block" data-ad-client="ca-pub-4498274213055914" :data-ad-slot="getAdsID()" data-ad-format="horizontal" />
    </div>
    <div v-if="type == 'ad-v'" class="text-center">
      <ins
        class="adsbygoogle"
        style="display: block"
        data-ad-client="ca-pub-4498274213055914"
        :data-ad-slot2="'1638399585'"
        :data-ad-slot="getAdsID()"
        data-ad-format="vertical"
        data-full-width-responsive="true"
      />
    </div>
    <div v-if="type == 'ad2-v'" class="d-grid text-center">
      <ins
        class="adsbygoogle"
        style="display: block"
        data-ad-client="ca-pub-4498274213055914"
        :data-ad-slot="getAdsID(3)"
        data-ad-format="vertical"
        data-full-width-responsive="true"
      />
    </div>
    <div v-if="type == 'ad2-a'">
      <ins
        class="adsbygoogle"
        style="display: block"
        data-ad-client="ca-pub-4498274213055914"
        :data-ad-slot="getAdsID(2)"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
    <div v-if="type == 'ad-a'">
      <ins
        class="adsbygoogle"
        style="display: block"
        data-ad-client="ca-pub-4498274213055914"
        :data-ad-slot="getAdsID(1)"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: String,
    id: String,
  },
  data() {
    return {
      adsId: ["1379860508", "9594537302", "6005526834", "3176494925"],
    };
  },
  methods: {
    getAdsID(n = 0) {
      return this.id;
      let id = this.$root.adsId;
      // Получаем текущее значение по индексу
      const res = this.adsId[id];
      console.log("dddd", res, id, this.adsId.length);
      // Увеличиваем индекс для следующего вызова
      id = (id + 1) % this.adsId.length;
      this.$root.adsId = id;
      console.log("next dddd", id);
      return res;
    },
  },
};
</script>