/** @format */

export default {
  data() {
    return {};
  },
  created() {},
  destroyed() {},
  methods: {
    getData(url) {
      url = this.$root.config.baseUrl + url;
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Ошибка при выполнении запроса');
          }
          return response.json();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    postData(url, d) {
      d = new URLSearchParams(d).toString();
      fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: d,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Ошибка при выполнении запроса');
          }
          return response;
        })
        .then((data) => {
          return true;
          // Обработка успешного ответа
        })
        .catch((error) => {
          console.error(error);
          return false;
        });
    },

    async postData2(url, d) {
      d = new URLSearchParams(d).toString();
      try {
        const response = await fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: d,
        });

        if (!response.ok) {
          throw new Error('Ошибка при выполнении запроса');
        }
        console.log('post data result', response);

        //const data = await response.json(); // Преобразование ответа в JSON
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
  },
};
