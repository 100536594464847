/** @format */

Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};

Date.prototype.addDays = function (h) {
  this.setTime(this.getTime() + h * 24 * 60 * 60 * 1000);
  return this;
};

Object.defineProperties(Date.prototype, {
  dayInMonth: {
    get: function () {
      const year = this.getFullYear();
      const month = this.getMonth();
      const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
      return [31, isLeapYear ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
    },
  },
  firstMonthDay: {
    get: function () {
      return new Date(this.getFullYear(), this.getMonth(), 1);
    },
  },
  lastMonthDay: {
    get: function () {
      return new Date(this.getFullYear(), this.getMonth(), this.dayInMonth);
    },
  },
  date: {
    get: function () {
      return new Date(this.getTime() - this.getTimezoneOffset() * 60000).toISOString().split('T')[0];
    },
  },
  time: {
    get: function () {
      return this.toTimeString().match(/\d{2}:\d{2}:\d{2}/)[0];
    },
  },
  datetime: {
    get: function () {
      return this.date + ' ' + this.time;
    },
  },
  datetimeDB: {
    get: function () {
      return this.date + 'T' + this.time + 'Z';
    },
  },
});
